	<template>
		<v-container>
			<v-layout row wrap>
				<v-flex xs12>
					<v-card>
						<v-toolbar flat color="#0f2e8c">
							<h3 class="white--text">QUẢN LÝ TÀI KHOẢN ĐẤU GIÁ YAHOO</h3>
							<v-spacer />
							<v-btn
								class="mx-2"
								color="blue"
								dark
								@click="doFetch()"
							>
								Tải lại dữ liệu
							</v-btn>
							<v-btn
								class="mx-2"
								color="green"
								dark
								@click="Box.Add = true"
							>
								Thêm tài khoản
							</v-btn>
						</v-toolbar>
						<v-card-title>
							<v-row>
								<v-col
									cols="auto"
								>
									<v-select
										v-model="Filter.Status"
										:items="StatusList"
										item-value="value"
										item-text="text"
										label="Trạng thái"
									/>
								</v-col>
								<v-spacer></v-spacer>
								<v-col
									cols="12"
									md="6"
									lg="4"
								>
									<v-text-field
										v-model="Search"
										append-icon="mdi-magnify"
										label="Tìm kiếm"
										single-line
										hide-details
										@change="doSearch()"
										v-on:keyup.enter="onSearch()"
									/>
								</v-col>
							</v-row>
						</v-card-title>
						<v-data-table
							:options.sync="options"
							:server-items-length="totalItems"
							:loading="Loader.Data"
							:headers="headers"
							:items="items"
							loading-text="Đang tải dữ liệu..."
							:footer-props="{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
							:items-per-page="50"
							item-key="_id"
						>
							<template v-slot:[`item.Account`]="{ item }">
                <div class="ma-1">
                  {{ item.Account }}
                  <v-btn
                    color="blue"
                    dark
                    x-small
                    icon
                    @click="doOpenAccount(item)"
                  >
                    <v-icon>
                      mdi-account-edit
                    </v-icon>
                  </v-btn>
                </div>
                <div class="ma-1">
                  {{ item.Email ? item.Email : 'Chưa có email xác thực' }}
                  <v-btn
                   color="blue"
                   dark
                   x-small
                   icon
                   @click="doOpenFix(item,'Email')"
                  >
                    <v-icon>
                      mdi-email-edit
                    </v-icon>
                  </v-btn>
                </div>
							</template>
              <template v-slot:[`item.VIPClient`]="{ item }">
                <template v-if="item['VIPClient']">
                  <v-chip
                      dark
                      color="green"
                  >
                    {{item['VIPClient']['Username']}}
                  </v-chip>
                  <v-btn
                    color="orange"
                    small
                    dark
                    @click="doRemoveVIP(item)"
                    class="mx-2"
                  >
                    Gỡ VIP
                  </v-btn>
                </template>
                <v-btn
                  class="ma-1"
                  dark
                  color="blue"
                  small
                  @click="doSetVipBox(item)"
                  v-if="!item['VIPClient'] && item['Status'] == 'dang_hoat_dong' && item['LinkedAccount'] === 0"
                >
                  Gắn VIP
                </v-btn>
                <v-btn
                    class="ma-1"
                    dark
                    color="blue"
                    small
                    @click="doFindCustomer(item)"
                    v-if="!item['VIPClient'] && item['LinkedAccount'] !== 0"
                >
                  Tìm khách hàng
                </v-btn>
              </template>
							<template v-slot:[`item.Status`]="{ item }">
								<template v-if="Status[item.Status]">
									<v-chip
										dark
										:color="Status[item.Status]['color']"
									>
										{{Status[item.Status]['text'] }}
									</v-chip>
								</template>
								<template v-else>
									{{item.Status}}
								</template>
							</template>
							<template v-slot:[`item.Action`]="{ item }">
<!--								<template v-if="Status[item.Status] && (item.Status === 'dang_loi' || item.Status === 'cho_xac_nhan' || item.Status === 'dang_hoat_dong')">-->
                  <v-btn
                   dark
                   @click="doOpenFix(item, 'Proxy')"
                   small
                   class="mx-2"
                   color="blue"
                  >
                    Proxy
                  </v-btn>
									<v-btn
										dark
										@click="doOpenFix(item)"
                    small
                    class="mx-2"
                    color="blue"
									>
										Nhập Cookie
									</v-btn>
<!--								</template>-->
                <v-btn
                    color="green"
                    dark
                    @click="doOpenFix(item,'FixPassword')"
                    small
                    class="mx-2"
                    v-if="!item['Password'] || item.Status === 'sai_pass' || item.Status === 'su_co' || item.Status === 'dang_loi'"
                >
                  C.nhật Mật khẩu
                </v-btn>
                <v-btn
                    :color="Status[item.Status]['color']"
                    dark
                    @click="doRemovePassword(item)"
                    small
                    class="mx-2"
                    v-if="item['Password']"
                    :disabled="Loader.RemovePassword === item._id"
                    :loading="Loader.RemovePassword === item._id"
                >
                  Gỡ mật khẩu
                </v-btn>
                <v-btn
                    :color="Status[item.Status]['color']"
                    dark
                    @click="doRemoveAccount(item)"
                    small
                    class="mx-2"
                    v-if="item['Status'] !== 'dang_hoat_dong' && item['Status'] !== 'da_xoa'"
                    :disabled="Loader.RemoveAccount === item._id"
                    :loading="Loader.RemoveAccount === item._id"
                >
                  Xóa tài khoản
                </v-btn>
                <v-btn
                    :color="Status[item.Status]['color']"
                    dark
                    @click="doPauseAccount(item)"
                    small
                    class="mx-2"
                    v-if="item['Status'] === 'dang_hoat_dong'"
                    :disabled="Loader.PauseAccount === item._id"
                    :loading="Loader.PauseAccount === item._id"
                >
                  Tạm ngừng sử dụng
                </v-btn>
                <v-btn
                    :color="Status[item.Status]['color']"
                    dark
                    @click="doSyncAccount(item)"
                    small
                    class="mx-2"
                    v-if="item['Status'] === 'su_co'"
                    :disabled="Loader.SyncAccount === item._id"
                    :loading="Loader.SyncAccount === item._id"
                >
                  Đồng bộ Cookie
                </v-btn>
							</template>
						</v-data-table>
					</v-card>
				</v-flex>
			</v-layout>
      <v-dialog
        v-model="Box.FindCustomer"
        persistent
        max-width="800px"
      >
        <v-card>
          <v-card-title>Tìm khách hàng liên kết</v-card-title>
          <v-card-text>
            <Loader :status="Loader.FindCustomer">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      Khách hàng
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="(customer, index) in LinkedCustomer"
                      :key="index"
                  >
                    <td>{{ customer }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </Loader>
          </v-card-text>
          <v-card-actions>
            <v-btn
              dark
              @click="Box.FindCustomer = false"
            >
              Đóng
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="Box.VIP"
        persistent
        max-width="800px"
      >
        <v-card>
          <v-card-title>Cập Nhật VIP</v-card-title>
          <v-card-text>
            <strong>Cảnh báo: </strong>
            <span class="red--text">
              Không sử dụng tài khoản Y! Auctions cho nhiều người dùng khác nhau khi gắn VIP !
            </span>
          </v-card-text>
          <v-card-text>
            <v-text-field
              v-model="VipMemberSearch"
              label="Tìm kiếm khách hàng"
              class="mx-2"
            />
            <v-btn
              small
              color="blue"
              class="mx-2"
              dark
              :disabled="VipMemberSearch.length < 4"
              @click="doSearchCustomer()"
            >
              Tìm
            </v-btn>
            <v-divider
              class="ma-2"
            />
            <Loader :status="Loader.VIP">
              <v-autocomplete
                v-model="SetVIP.Customer"
                :items="CustomerList"
                label="Khách hàng VIP"
                v-if="CustomerList.length > 0"
              />
              <v-chip
                color="orange"
                v-else
                dark
                text
              >
                Không có khách hàng
              </v-chip>
            </Loader>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="green"
              dark
              v-if="SetVIP.ID !== null && SetVIP.Customer !== null"
              @click="doSetVIP()"
              :loading="Loader.VIP"
            >
              Cài đặt
            </v-btn>
            <v-spacer />
            <v-btn
              dark
              @click="Box.VIP = false"
            >
              Đóng
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
			<v-dialog
				v-model="Box.Add"
				max-width="600px"
				persistent
			>
				<v-card flat>
					<v-form v-model="FormValidation.Add">
						<v-card-title>
							Thêm tài khoản Yahoo
						</v-card-title>
						<v-card-text>
							<v-text-field
								class="mx-12"
								label="Tài khoản"
								v-model="AccountData.Account"
								:rules="[
									v => !!v || 'Tài khoản không được để trống',
									//v => /^[a-zA-Z0-9]+$/.test(v) || 'Tài khoản không được chứa ký tự đặc biệt'
								]"
							/>
              <v-radio-group
                  v-model="AccountData.Mode"
                  row
                  class="mx-12"
              >
                <v-radio
                    label="Cookie"
                    value="Cookie"
                ></v-radio>
                <v-radio
                    label="Mật khẩu"
                    value="Password"
                ></v-radio>
              </v-radio-group>
							<v-textarea
								class="mx-12"
								label="Cookie"
								v-model="AccountData.Cookie"
								:rules="[
									v => (!!v || AccountData.Mode !== 'Cookie') || 'Thông tin Cookie không được trống !!!',
								]"
                v-if="AccountData.Mode === 'Cookie'"
							/>
              <v-text-field
                  class="mx-12"
                  label="Mật khẩu"
                  v-model="AccountData.Password"
                  :rules="[
									v => (!!v || AccountData.Mode !== 'Password') || 'Mật khẩu không được để trống',
								]"
                  v-if="AccountData.Mode === 'Password'"
              />
              <v-text-field
               class="mx-12"
               label="Email xác thực"
               v-model="AccountData.Email"
               :rules="[
									v => !!v || 'Email xác thực không được để trống',
									//v => /^[a-zA-Z0-9]+$/.test(v) || 'Tài khoản không được chứa ký tự đặc biệt'
								]"
                v-if="AccountData.Mode === 'Password'"
              />
              <v-text-field
               class="mx-12"
               label="Proxy"
               v-model="AccountData.Proxy"
               :rules="[
									v => !!v || 'Proxy không được để trống',
									//v => /^[a-zA-Z0-9]+$/.test(v) || 'Tài khoản không được chứa ký tự đặc biệt'
								]"
              />
              <v-text-field
               class="mx-12"
               label="Proxy User"
               v-model="AccountData.Proxy_User"
              />
              <v-text-field
               class="mx-12"
               label="Proxy Pass"
               v-model="AccountData.Proxy_Pass"
              />
						</v-card-text>
						<v-card-actions>
							<v-btn
								color="green"
								dark
								:disabled="!FormValidation.Add || Loader.Add"
								:loading="Loader.Add"
								@click="doCreate()"
							>
								<v-icon left>mdi-check</v-icon>
								Lưu
							</v-btn>
							<v-spacer />
							<v-btn
								dark
								@click="doClose('Add')"
							>
								Đóng
							</v-btn>
						</v-card-actions>
					</v-form>
					<loading :status="Loader.Add" />
				</v-card>
			</v-dialog>
			<v-dialog
				v-model="Box.Fix"
				max-width="600px"
				persistent
			>
				<v-card flat>
					<v-form v-model="FormValidation.Fix">
						<v-card-title>
							Cập nhật Cookie cho tài khoản
						</v-card-title>
						<v-card-text>
							<span class="mx-12">
								<strong>Tài khoản: </strong>
								<v-chip
								color="blue"
								dark
								>
									{{AccountData.Account}}
								</v-chip>
							</span>
							<v-textarea
								class="mx-12"
								label="Cookie"
								v-model="AccountData.Cookie"
								:rules="[
									v => !!v || 'Thông tin Cookie không được trống !!!',
								]"
							/>
						</v-card-text>
						<v-card-actions>
							<v-btn
								color="green"
								dark
								:disabled="!FormValidation.Fix || Loader.Fix"
								:loading="Loader.Fix"
								@click="doFixCookie()"
							>
								<v-icon left>mdi-auto-fix</v-icon>
								Cập nhật
							</v-btn>
							<v-spacer />
							<v-btn
								dark
								@click="doClose('Fix')"
							>
								Đóng
							</v-btn>
						</v-card-actions>
					</v-form>
					<loading :status="Loader.Fix" />
				</v-card>
			</v-dialog>
      <v-dialog
          v-model="Box.FixPassword"
          max-width="600px"
          persistent
      >
        <v-card flat>
          <v-form v-model="FormValidation.Fix">
            <v-card-title>
              Cập nhật mật khẩu cho tài khoản
            </v-card-title>
            <v-card-text>
							<span class="mx-12">
								<strong>Tài khoản: </strong>
								<v-chip
                    color="blue"
                    dark
                >
									{{AccountData.Account}}
								</v-chip>
							</span>
              <v-text-field
                  class="mx-12"
                  label="Mật khẩu"
                  v-model="AccountData.Password"
                  :rules="[
									v => !!v || 'Thông tin mật khẩu không được trống !!!',
								]"
              />
            </v-card-text>
            <v-card-actions>
              <v-btn
                  color="green"
                  dark
                  :disabled="!FormValidation.Fix || Loader.Fix"
                  :loading="Loader.Fix"
                  @click="doFixPassword()"
              >
                <v-icon left>mdi-auto-fix</v-icon>
                Cập nhật
              </v-btn>
              <v-spacer />
              <v-btn
                  dark
                  @click="doClose('FixPassword')"
              >
                Đóng
              </v-btn>
            </v-card-actions>
          </v-form>
          <loading :status="Loader.Fix" />
        </v-card>
      </v-dialog>
      <v-dialog
				v-model="Box.Account"
				max-width="600px"
				persistent
			>
				<v-card flat>
					<v-form v-model="FormValidation.Account">
						<v-card-title>
							Cập nhật tên gợi nhớ cho tài khoản
						</v-card-title>
						<v-card-text>
							<v-text-field
								class="mx-12"
								label="Tài khoản"
								v-model="AccountData.Account"
								:rules="[
									v => !!v || 'Tài khoản không được để trống',
									//v => /^[a-zA-Z0-9]+$/.test(v) || 'Tài khoản không được chứa ký tự đặc biệt'
								]"
							/>
						</v-card-text>
						<v-card-actions>
							<v-btn
								color="green"
								dark
								:disabled="!FormValidation.Account || Loader.Account"
								:loading="Loader.Account"
								@click="doUpdateAccount()"
							>
								<v-icon left>mdi-auto-fix</v-icon>
								Cập nhật
							</v-btn>
							<v-spacer />
							<v-btn
								dark
								@click="doClose('Account')"
							>
								Đóng
							</v-btn>
						</v-card-actions>
					</v-form>
					<loading :status="Loader.Account" />
				</v-card>
			</v-dialog>
      <v-dialog
       v-model="Box.Proxy"
       max-width="600px"
       persistent
      >
        <v-card flat>
          <v-form v-model="FormValidation.Account">
            <v-card-title>
              Cập nhật Proxy cho tài khoản
            </v-card-title>
            <v-card-text>
              <v-text-field
               class="mx-12"
               label="Proxy"
               v-model="AccountData.Proxy"
              />
            </v-card-text>
            <v-card-text>
              <v-text-field
               class="mx-12"
               label="Proxy User"
               v-model="AccountData.Proxy_User"
              />
            </v-card-text>
            <v-card-text>
              <v-text-field
               class="mx-12"
               label="Proxy Password"
               v-model="AccountData.Proxy_Pass"
              />
            </v-card-text>
            <v-card-actions>
              <v-btn
               color="green"
               dark
               :disabled="!FormValidation.Account || Loader.Proxy"
               :loading="Loader.Proxy"
               @click="doUpdateProxy()"
              >
                <v-icon left>mdi-auto-fix</v-icon>
                Cập nhật
              </v-btn>
              <v-spacer />
              <v-btn
               dark
               @click="doClose('Proxy')"
              >
                Đóng
              </v-btn>
            </v-card-actions>
          </v-form>
          <loading :status="Loader.Proxy" />
        </v-card>
      </v-dialog>
      <v-dialog
       v-model="Box.Email"
       max-width="600px"
       persistent
      >
        <v-card flat>
          <v-form v-model="FormValidation.Account">
            <v-card-title>
              Cập nhật Email xác nhận cho tài khoản
            </v-card-title>
            <v-card-text>
              <v-text-field
               class="mx-12"
               label="Email"
               v-model="AccountData.Email"
              />
            </v-card-text>
            <v-card-actions>
              <v-btn
               color="green"
               dark
               :disabled="!FormValidation.Account || Loader.Email"
               :loading="Loader.Email"
               @click="doUpdateEmail()"
              >
                <v-icon left>mdi-auto-fix</v-icon>
                Cập nhật
              </v-btn>
              <v-spacer />
              <v-btn
               dark
               @click="doClose('Email')"
              >
                Đóng
              </v-btn>
            </v-card-actions>
          </v-form>
          <loading :status="Loader.Proxy" />
        </v-card>
      </v-dialog>
			<loading :status="Loader.Data" />
		</v-container>
</template>
<script>
import loading from '@/components/base/Loading.vue';
import YAccountsQuery from '@/plugins/query/yaccounts.js';
import { Status } from '@/plugins/helper/dstatus';
import {serialize} from '@/plugins/helper/object';
import {YahooAccount} from '@/plugins/helper/status';
import headers from '@/plugins/headers/yaccounts.json';
import {mapGetters} from "vuex";
import Loader from '@/components/Loader';
export default {
	name: "YahooAccount",
	components: {
		loading,
    Loader
	},
	data(){
		return {
      LinkedCustomer: [],
			AccountMode: null,
			totalItems: 0,
			items: [],
			options:{},
			Search: null,
			Filter:{
				Status: null,
				Search: null,
			},
			Loader:{
				Data: false,
				Add: false,
				Fix: false,
				Account: false,
        Customer: false,
        VIP: false,
        FindCustomer: false,
        RemovePassword: null,
        RemoveAccount: null,
        PauseAccount: null,
        SyncAccount: null,
        Proxy: false,
        Email: false,
			},
			AccountData:{
				Account: null,
        Password: null,
				Cookie: null,
				ID: null,
        Mode: 'Password',
        Proxy: '',
        Proxy_User: '',
        Proxy_Pass: '',
			},
			Box:{
				Add: false,
				Fix: false,
        FixPassword: false,
				Account: false,
        VIP: false,
        FindCustomer: false,
        Proxy: false,
        Email: false,
			},
			FormValidation:{
				Add: false,
				Fix: false,
				Account: false,
			},
      SetVIP:{
        ID: null,
        Customer: null,
      },
      VipMemberSearch: '',
      CustomerList:[],
			timeOut: null,
		}
	},
	computed:{
		...mapGetters(['authStatus','isAuthenticated','loginInfo']),
		notice(){ return new Status(this.$swal)},
		headers(){
			let data = headers;
			return data;
		},
		Status(){
			return YahooAccount;
		},
		StatusList(){
			const ListStatus = Object.keys(YahooAccount).map(item=>({
				value:item,
				text:YahooAccount[item]['text'],
			}));
			return [{value:null,text:'Tất cả'}].concat(ListStatus);
		},
		isAllowedPartner(){
			if(this.loginInfo._perm === 'admin' || this.loginInfo._perm === 'manager'){
				return {
					Partner: true,
					Auction: true,
				};
			}
			if(this.loginInfo._perm === 'warehouse'){
				return {
					Partner: true,
					Auction: false,
				};
			}
			if(this.loginInfo._perm === 'sale') {
				if(this.loginInfo.Limited === false){
					return this.loginInfo.Rules;
				}
			}
			return false;
		},
	},
	watch: {
		Filter: {
			handler () {
				this.doFetch();
			},
			deep: true,
		},
		options: {
			handler () {
				this.doFetch();
			},
			deep: true,
		},
    Box: {
      handler () {
        if(this.Box.Add === true){
          this.AccountData = {
            Account: null,
            Password: null,
            Cookie: null,
            ID: null,
            Mode: 'Password',
            Proxy: '',
            Proxy_Pass: '',
            Proxy_User: '',
          };
        }
      },
      deep: true,
    },
	},
	methods:{
		...YAccountsQuery,
    doSyncAccount(item){
      const app = this;
      return this.notice.confirm("Xác nhận ?","Hành động này không thể phục hồi !!!").then(success=>{
        if(success){
          app.Loader.SyncAccount = item['_id'];
          return app.qrSyncAccount(item['_id']).then(json=>{
            const {error, success} = json;
            if(success){
              app.notice.success('Thành công');
              app.doFetch();
            }
            if(error){
              app.notice.error(error);
            }
          }).catch(err=>{
            app.notice.error(err.message);
          }).finally(()=>app.Loader.SyncAccount = null);
        }
      })
    },
    doPauseAccount(item){
      const app = this;
      return this.notice.confirm("Xác nhận ?","Hành động này không thể phục hồi !!!").then(success=>{
        if(success){
          app.Loader.PauseAccount = item['_id'];
          return app.qrPauseAccount(item['_id']).then(json=>{
            const {error, success} = json;
            if(success){
              app.notice.success('Thành công');
              app.doFetch();
            }
            if(error){
              app.notice.error(error);
            }
          }).catch(err=>{
            app.notice.error(err.message);
          }).finally(()=>app.Loader.PauseAccount = null);
        }
      })
    },
    doRemoveAccount(item){
      const app = this;
      return this.notice.confirm("Xác nhận ?","Hành động này không thể phục hồi !!!").then(success=>{
        if(success){
          app.Loader.RemoveAccount = item['_id'];
          return app.qrRemoveAccount(item['_id']).then(json=>{
            const {error, success} = json;
            if(success){
              app.notice.success('Thành công');
              app.doFetch();
            }
            if(error){
              app.notice.error(error);
            }
          }).catch(err=>{
            app.notice.error(err.message);
          }).finally(()=>app.Loader.RemoveAccount = null);
        }
      })
    },
    doRemovePassword(item){
      const app = this;
      return this.notice.confirm("Xác nhận ?","Hành động này không thể phục hồi !!!").then(success=>{
        if(success){
          app.Loader.RemovePassword = item['_id'];
          return app.qrRemovePassword(item['_id']).then(json=>{
            const {error, success} = json;
            if(success){
              app.notice.success('Thành công');
              app.doFetch();
            }
            if(error){
              app.notice.error(error);
            }
          }).catch(err=>{
            app.notice.error(err.message);
          }).finally(()=>app.Loader.RemovePassword = null);
        }
      })
    },
    doFindCustomer(item){
      this.LinkedCustomer = [];
      this.Box.FindCustomer = true;
      this.Loader.FindCustomer = true;
      this.qrFindCustomer(item['_id']).then(json=>{
        const {error, data} = json;
        if(error){
          this.notice.error(error);
        }
        if(data){
          this.LinkedCustomer = data;
        }
      }).catch(err=>{
        this.notice.error(err.message);
      }).finally(()=>{
        this.Loader.FindCustomer = false;
      });
    },
    doRemoveVIP(item){
      this.Loader.Data = true;
      this.qrRemoveVIP(item._id).then(json=>{
        const {error, success} = json;
        if(success){
          this.notice.success('Thành công');
          this.doFetch();
        }
        if(error){
          this.notice.error(error);
        }
      }).catch(err=>{
        this.notice.error(err.message);
      });
    },
    doSetVIP(){
      const {ID, Customer} = this.SetVIP;
      if(ID === null || Customer === null){
        this.notice.error('Vui lòng chọn khách hàng');
        return;
      }
      this.Loader.VIP = true;
      this.qrSetVIP(ID, Customer).then(json=>{
        const {error, success} = json;
        if(success){
          this.notice.success('Thành công');
          this.Box.VIP = false;
          this.doFetch();
        }
        if(error){
          this.notice.error(error);
        }
      }).catch(err=>{
        this.notice.error(err.message);
      }).finally(()=>{
        this.Loader.VIP = false;
      });
    },
    doSearchCustomer(){
      const Customer = this.VipMemberSearch.toString();
      if(Customer.length >= 4){
        this.Loader.VIP = true;
        this.qrCustomer(Customer).then(json=>{
          this.CustomerList = json.data;
        }).catch(err=>{
          this.notice.error(err.message);
        }).finally(()=>{
          this.Loader.VIP = false;
        });
      }else{
        this.notice.error('Nội dung tìm kiếm phải lớn hơn 3 kí tự !!!');
      }
    },
    doSetVipBox(item){
      this.VipMemberSearch = '';
      this.CustomerList = [];
      this.SetVIP = {
        ID: item._id,
        Customer: null,
      };
      this.Box.VIP = true;
    },
    doUpdateEmail(){
      const {Email, ID} = this.AccountData;
      if(Email && ID){
        this.Loader.Email = true;
        this.qrEmail(ID, Email).then(json=>{
          const {success, error} = json;
          if(error){
            this.notice.error(error);
          }
          if(success){
            this.notice.success('Email xác nhận tài khoản đã cập nhật !!!');
            this.Box.Proxy = false;
            this.AccountData = {
              Account: null,
              Password: null,
              Email: null,
              Cookie: null,
              ID: null,
              Mode: 'Password',
              Proxy: '',
              Proxy_User: '',
              Proxy_Pass: '',
            };
            this.doFetch();
          }
        })
         .catch(e=>{
           console.error(e);
           this.notice.error(e.message);
         })
         .finally(()=>{
           this.Loader.Email = false;
         });
      }else{
        this.notice.error('Thông tin không hợp lệ !!!');
      }
    },
    doUpdateProxy(){
      const {Proxy, Proxy_Pass, Proxy_User, ID} = this.AccountData;
      if(Proxy && ID){
        this.Loader.Proxy = true;
        this.qrProxy(ID, {
          Proxy,
          Proxy_Pass,
          Proxy_User,
        }).then(json=>{
          const {success, error} = json;
          if(error){
            this.notice.error(error);
          }
          if(success){
            this.notice.success('Tài khoản đã cập nhật !!!');
            this.Box.Proxy = false;
            this.AccountData = {
              Account: null,
              Password: null,
              Cookie: null,
              ID: null,
              Mode: 'Password',
              Proxy: Proxy,
              Proxy_User: Proxy_User,
              Proxy_Pass: Proxy_Pass,
            };
            this.doFetch();
          }
        })
         .catch(e=>{
           console.error(e);
           this.notice.error(e.message);
         })
         .finally(()=>{
           this.Loader.Proxy = false;
         });
      }else{
        this.notice.error('Thông tin không hợp lệ !!!');
      }
    },
		doUpdateAccount(){
			const {Account, ID} = this.AccountData;
			if(Account && ID){
				this.Loader.Account = true;
				this.qrAccount(ID, Account).then(json=>{
					const {success, error} = json;
					if(error){
						this.notice.error(error);
					}
					if(success){
						this.notice.success('Tài khoản đã cập nhật !!!');
						this.Box.Account = false;
            this.AccountData = {
              Account: null,
              Password: null,
              Cookie: null,
              ID: null,
              Mode: 'Password',
            };
						this.doFetch();
					}
				})
        .catch(e=>{
          console.error(e);
          this.notice.error(e.message);
        })
        .finally(()=>{
          this.Loader.Account = false;
        });
			}else{
				this.notice.error('Thông tin không hợp lệ !!!');
			}
		},
		doOpenAccount(item){
			this.AccountData = {
				Account: item.Account,
        Password: item.Password,
				Cookie: null,
				ID: item._id,
        Mode: 'Password',
        Proxy: '',
        Proxy_User: '',
        Proxy_Pass: '',
			}
			this.Box.Account = true;
		},
    doOpenEmailUpdate(item){
      this.AccountData = {
        Account: item.Account,
        Password: item.Password,
        Email: item.Email,
        Cookie: null,
        ID: item._id,
        Mode: 'Password',
        Proxy: '',
        Proxy_User: '',
        Proxy_Pass: '',
      }
      this.Box.Email = true;
    },
    doFixPassword(){
      const {Account, Password, ID} = this.AccountData;
      if(Account && Password && ID){
        this.Loader.Fix = true;
        this.qrFixPassword(ID, {Password}).then(json=>{
          const {success, error} = json;
          if(error){
            this.notice.error(error);
          }
          if(success){
            this.notice.success('Tài khoản đã cập nhật mật khẩu !!!');
            this.Box.FixPassword = false;
            this.AccountData = {
              Account: null,
              Password: null,
              Cookie: null,
              ID: null,
              Mode: 'Password',
            };
            this.doFetch();
          }
        })
        .catch(e=>{
          console.error(e);
          this.notice.error(e.message);
        })
        .finally(()=>{
          this.Loader.Fix = false;
        });
      }else{
        this.notice.error('Thông tin không hợp lệ !!!');
      }
    },
		doFixCookie(){
			const {Account, Cookie, ID} = this.AccountData;
			if(Account && Cookie && ID){
				this.Loader.Fix = true;
				this.qrFixCookie(ID, {Cookie}).then(json=>{
					const {success, error} = json;
					if(error){
						this.notice.error(error);
					}
					if(success){
						this.notice.success('Tài khoản đã kích hoạt lại !!!');
						this.Box.Fix = false;
            this.AccountData = {
              Account: null,
              Password: null,
              Cookie: null,
              ID: null,
              Mode: 'Password',
            };
						this.doFetch();
					}
				})
				.catch(e=>{
					console.error(e);
					this.notice.error(e.message);
				})
				.finally(()=>{
					this.Loader.Fix = false;
				});
			}else{
				this.notice.error('Thông tin không hợp lệ !!!');
			}
		},
		doOpenFix(item, mode='Fix'){
			this.AccountData = {
				Account: item.Account,
				Cookie: null,
				ID: item._id,
        Proxy: item.Proxy,
        Proxy_User: item.Proxy_User,
        Proxy_Pass: item.Proxy_Pass,
			}
			this.Box[mode] = true;
		},
		doFetch(){
			this.Loader.Data = true;
			let Query = Object.assign({},this.Filter);
			const {page, itemsPerPage} = this.options;
			Query['page'] = page;
			Query['itemsPerPage'] = itemsPerPage;
			this.qrList(serialize(Query)).then(res => {
				this.items = res.data;
				this.totalItems = res.total;
			}).catch(err => {
				this.notice.error(err.message);
			}).finally(()=>{
				this.Loader.Data = false;
			})
		},
		doCreate(){
			const {AccountData} = this;
			const {Account, Cookie, Mode, Password} = AccountData;
      if (Mode === 'Cookie' && !Cookie) {
        this.notice.error('Bạn chưa nhập cookie !!!');
        return;
      }
      if (Mode === 'Password' && !Password) {
        this.notice.error('Bạn chưa nhập mật khẩu !!!');
        return;
      }
      this.Loader.Add = true;
      this.qrCreate(AccountData).then(json=>{
        const {error, success} = json;
        if(error){
          this.notice.error(error);
        }
        if(success){
          this.notice.success('Đã thêm tài khoản thành công !!!');
          this.doClose('Add');
          //this.doRefresh();
        }
      })
      .catch(e=>{
        console.error(e);
        this.notice.error(e.message);
      })
      .finally(()=>{
        this.Loader.Add = false;
      });
		},
		onSearch(){
			this.Filter.Search = this.Search;
		},
		doSearch(){
			clearTimeout(this.timeOut);
			const app = this;
			this.timeOut = setTimeout(() => {
				app.Filter.Search = app.Search;
			}, 100);
		},
		doClose(Box){
			this.Box[Box]= false;
			if(Box === 'Add' || Box === 'Fix' || Box === 'Account') {
				this.AccountData = {
          Account: null,
          Password: null,
          Cookie: null,
          ID: null,
          Mode: 'Password',
          Proxy: '',
          Proxy_Pass: '',
          Proxy_User: '',
        };
			}

		},
	},
	mounted() {
		const {isAllowedPartner} = this;
		if(isAllowedPartner === false || isAllowedPartner['Auction'] !== true){
			this.$router.push('/');
			return;
		}
	}
}
</script>

<style scoped>

</style>